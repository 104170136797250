import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";

const Blog = () => {
  return (
    <Root>
      <SEO title="Blog" />
      <h1>Blog</h1>
    </Root>
  );
};

export default Blog;
